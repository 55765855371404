import * as React from "react";
import { Switch } from "react-router-dom";
import AsyncImport from '@/components/AsyncImport';
import AuthRouter from "@/components/Router/AuthRouter";

//machineGoodsClass
export default () => {
    return <Switch>
        <AuthRouter path="/home" component={AsyncImport(() => import('@/pages/home/index'))} exact /> {/*首页*/}
        <AuthRouter path="/raw/supplier" component={AsyncImport(() => import('@/pages/raw/supplier/index'))} />  {/*原料供应商*/}
        <AuthRouter path="/raw/car" component={AsyncImport(() => import('@/pages/raw/car/index'))} /> {/*原料车辆*/}
        <AuthRouter path="/raw/raw-materia" component={AsyncImport(() => import('@/pages/raw/raw-materia/index'))} /> {/*原材料*/}
        <AuthRouter path="/raw/raw-materia-price" component={AsyncImport(() => import('@/pages/raw/raw-materia-price/index'))} /> {/*原材料价格*/}
        <AuthRouter path="/sale/customer" component={AsyncImport(() => import('@/pages/sale/customer/index'))} /> {/*客户管理*/}
        <AuthRouter path="/sale/customer-cate" component={AsyncImport(() => import('@/pages/sale/customer-cate/index'))} /> {/*客户类别管理*/}
        <AuthRouter path="/sale/customer-recharge-record" component={AsyncImport(() => import('@/pages/sale/customer-recharge-record/index'))} /> {/*客户预存款*/}
        <AuthRouter path="/sale/customer-balance-record/:customer_id?" component={AsyncImport(() => import('@/pages/sale/customer-balance-record/index'))} /> {/*账目流水*/}
        <AuthRouter path="/sale/pack" component={AsyncImport(() => import('@/pages/sale/pack/index'))} /> {/*品种打包*/}
        <AuthRouter path="/sale/stone" component={AsyncImport(() => import('@/pages/sale/stone/index'))} /> {/*品种*/}
        <AuthRouter path="/sale/stone-price" component={AsyncImport(() => import('@/pages/sale/stone-price/index'))} /> {/*品种*/}
        <AuthRouter path="/sale/customer-truck" component={AsyncImport(() => import('@/pages/sale/customer-truck/index'))} /> {/*客户车牌*/}
        <AuthRouter path="/order/raw-order" component={AsyncImport(() => import('@/pages/order/raw-order/index'))} /> {/*原料订单管理*/}
        <AuthRouter path="/order/sale-order/:order_number?" component={AsyncImport(() => import('@/pages/order/sale-order/index'))} /> {/*销售订单管理*/}
        <AuthRouter path="/apply-order/:type?/:dispatch_id?" component={AsyncImport(() => import('@/pages/order/apply-order/index'))} /> {/*申请订单管理*/}
        <AuthRouter path="/dispatch-order/:type?" component={AsyncImport(() => import('@/pages/order/dispatch-order/index'))} /> {/*派单管理*/}
        <AuthRouter path="/photos/:scene-photo" component={AsyncImport(() => import('@/pages/photos/index'))} /> {/*图片管理*/}
        <AuthRouter path="/log/:scene-log" component={AsyncImport(() => import('@/pages/logs/index'))} /> {/*日志管理*/}
        <AuthRouter path="/report/sale-report/sale-statistics"  component={AsyncImport(() => import('@/pages/report/sale-statistics'))} /> {/*统计汇总报表*/}
        <AuthRouter path="/report/sale-report/sale-detail"  component={AsyncImport(() => import('@/pages/report/sale-detail'))} /> {/*统计明细报表*/}
        <AuthRouter path="/report/sale-report/sale-detail-group"  component={AsyncImport(() => import('@/pages/report/sale-detail-group'))} />{/*分组明细报表*/}
        <AuthRouter path="/report/sale-report/sale-receivable"  component={AsyncImport(() => import('@/pages/report/sale-receivable'))} />{/*应收账款*/}
        <AuthRouter path="/report/sale-report/raw-detail-group"  component={AsyncImport(() => import('@/pages/report/raw-detail-group'))} />{/*原料分组明细报表*/}
        <AuthRouter path="/user/user" component={AsyncImport(() => import('@/pages/user/index'))} /> {/*用户管理*/}
        <AuthRouter path="/factory/editpass" component={AsyncImport(() => import('@/pages/factory/edit-pass'))} /> {/*修改密码*/}
        <AuthRouter path="/factory/factory-set/:type" component={AsyncImport(() => import('@/pages/factory/factory-set'))} /> {/*厂区设置*/}
        <AuthRouter path="/factory/print-set" component={AsyncImport(() => import('@/pages/factory/print-set'))} /> {/*打印设置*/}
        <AuthRouter path="/factory-info" component={AsyncImport(() => import('@/pages/factory/factory-info'))} /> {/*厂区设置*/}
        <AuthRouter path="/announcement" component={AsyncImport(() => import('@/pages/announcement/index'))} /> {/*厂区设置*/}
        <AuthRouter path="/report/sale-report/raw-statistics" component={AsyncImport(() => import('@/pages/report/raw-statics'))} /> {/*原料统计报表*/}
        <AuthRouter path="/client-web/:ReportUrl" component={AsyncImport(() => import('@/pages/client/index'))} /> {/*原料统计报表*/}
    </Switch>
}