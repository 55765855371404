import request, { ApiResponse } from "./request";

/**
 * 配置api查询类
 */
export default class Config {
    /**
     * 配置
     * @param formData
     * @returns {AxiosPromise<any>}
     */
    static list = (type = 2) => {
        return request.get<any,ApiResponse>("config/list",{
            params:{
                type
            }
        });
    };


    // static 


    static update = (formData, type) => {
        return request.post<any,ApiResponse>("config/setConfig",{
            ...formData, type
        });
    }

    
}