import * as React from 'react'
import { Dropdown, Menu, Avatar,Button,message,Modal } from 'antd';
import styled from "styled-components";
import user from "@/utils/user";
import {withRouter,Link, RouteComponentProps} from "react-router-dom";
import {  UserOutlined, LogoutOutlined } from '@ant-design/icons';
import Home from '@/api/home';
import { WrapAuthButton } from '../Auth/WrapAuth';

const { confirm } = Modal;

let HeaderRight:React.FC<RouteComponentProps> = (props) => {
    const menu = (
        <Menu>
            <Menu.Item>
                <Link  to="/editpass">修改密码</Link>
            </Menu.Item>
        </Menu>
    );
    // 退出登录
    const logout = async () => {
        await user.logout();
        message.success("退出登录成功");
        props.history.replace("/login");
    };

    const initData = async (i = 1) => {
        confirm({
            title: '您确认要初始化数据吗?',
            content: '初始化数据之后，系统数据除了品种数据其他全部清空，并且不可以找回！！请谨慎操作！！总共需要确认5此，当前第'+i+'次',
            okText: '初始化',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                if(i === 5){
                    Home.initData().then(result => {
                        message.success("初始化成功");
                        logout();
                    })
                }else{
                    i++;
                    initData(i);
                }
              console.log('OK');
            },
            onCancel() {
              console.log('取消');
            },
          });
    }

    const HeaderRightDiv = styled.div`
        position:absolute;
        right:55px;
    `;

    const username = user.getUserInfor().username;
    return <HeaderRightDiv>
        <Dropdown overlay={menu}>
            <a className="ant-dropdown-link" href="#">
                <Avatar icon={<UserOutlined translate="" />} style={{marginRight:"5px"}} /> {username} 
            </a>
        </Dropdown>
        <WrapAuthButton auth="init-order" type="dashed" onClick={() => initData(1)}>初始化数据</WrapAuthButton>
        <Button danger onClick={logout} icon={<LogoutOutlined translate="" />} style={{marginLeft:"15px"}} >
            退出登录
        </Button>
    </HeaderRightDiv>
};

export default  withRouter(HeaderRight);