import { RouterStore } from "mobx-react-router";
import Config from "./config";

// const files = require.context('.', false, /\.js$/)

// require.

class RootStore {
  routing:RouterStore|any;

  config:Config = new Config();

  constructor() {

  }
}




export default new RootStore();