import * as React from 'react';
import styled from "styled-components";
import {inject, observer} from 'mobx-react';

const SoftTitle = styled.div`
    margin-left: 25px;
    font-size: 18px;
    font-weight: bold;
    color: #1890ff;
`;

let HeaderLeft:React.FC<any> = (props)=>{
    return <SoftTitle>{(props.factorySet.factory_name || "")+"管理后台"}</SoftTitle>
};

export default inject((stores:any) => ({
    factorySet:stores.config.factorySet
}))(observer(HeaderLeft));

// export default HeaderLeft;