import * as React from "react";
import HeaderRight from "@/components/Layout/HeaderRight";
import HeaderLeft from "@/components/Layout/HeaderLeft";
import MainRouter from "@/components/Router/Main";
import { Layout as AntdLayout } from "antd";
import MyMenu from "@/components/Layout/MyMenu";
import styled from "styled-components";
import { inject, observer } from 'mobx-react';



class Layout extends React.Component<{
    configInit:any;
    initFactorySet:any;
    factorySet:any;
}> {

    componentDidMount(){
        this.props.configInit();
        this.props.initFactorySet();
    }

    render() {
        return <AntdLayout style={{ minHeight: '100vh' }}>
            <AntdLayout.Sider breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={broken => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}>
                <ImgWrap>
            <LogoTitle>{this.props.factorySet.factory_name}</LogoTitle>
                </ImgWrap>
                <MyMenu />
            </AntdLayout.Sider>
            <AntdLayout>
                <AntdLayout.Header style={{ background: '#fff', padding: 0, display: 'flex' }} >
                    <HeaderLeft />
                    <HeaderRight />
                </AntdLayout.Header>
                <AntdLayout.Content style={{ padding: "15px", marginTop: "10px" }}>
                    <MainRouter />
                </AntdLayout.Content>
            </AntdLayout>
        </AntdLayout>
    }
}
export default inject((stores:any) => ({
    configInit:stores.config.init,
    initFactorySet:stores.config.initFactorySet,
    factorySet:stores.config.factorySet
}))(observer(Layout))

const Img = styled.img`
    height:50px;
`;

const ImgWrap = styled.div`
    height:100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom:1px solid #2d2d2d;
`;

const LogoTitle = styled.div`
    color: #d11f22;
    font-size: 18px;
    font-weight: bold;
    /* box-shadow: 0px 0px 80px #999; */
    text-shadow: 0 0px 60px #fff;
    background: transparent;
`