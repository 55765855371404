import * as _ from "lodash";

// 用户信息
export const USER_INFO_KEY = 'userinfor';

// 用户token
export const USER_TOKEN_KEY = 'token';

// 互联网远程访问对应的三级域名映射和端口
export const REMOTE_SERVER = {
    domain:"lxyly.cn",
    backend:"api.wyg",
    frontend:"admin.wyg",
    photo:"photo.wyg",
    port:8089
}

export const API_PORT = process.env.NODE_ENV === 'production' ? 9999 : 12010;

export const HTTP = 'https';

const SERVER_HOST = () => {
   // return "api.wnq.lxyly.cn:8089"
    let hostnameArr = _.split(window.location.hostname, '.');
    let domain = hostnameArr.slice(-2).join(".");
    let serverhost = "";
    if(domain === REMOTE_SERVER.domain){
        hostnameArr[0] = REMOTE_SERVER.backend;
        serverhost = REMOTE_SERVER.backend+"."+REMOTE_SERVER.domain+":"+REMOTE_SERVER.port;
    }else{
        serverhost = window.location.hostname+":"+API_PORT;
    }
    return serverhost
}



export const API_URL = HTTP + "://haojian.lxyly.cn"; 



