export default {
    get(key:string, defaults:any = null){
        let value = sessionStorage.getItem(key)
        if(value !== null){
            return JSON.parse(value);
        }else{
            return defaults
        }
    },

    set(key:string, value:object|string){
        sessionStorage.setItem(key, JSON.stringify(value));
    },

    remove(key:string){
        sessionStorage.removeItem(key);
    },

    clear(){
        sessionStorage.clear();
    }

}