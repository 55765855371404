import request, { ApiResponse } from "./request";

/**
 * 登录系统
 * @param formData {username,password}
 * @returns {AxiosPromise<any>}
 */
export const login = (formData) => {
    return request.post<any,ApiResponse>('login/login',formData);
};

// 获取厂区设置
export const getFactoryset = () => {
    return request.get<any,ApiResponse>('login/getFactoryset');
}


// 设置厂区设置
export const setFactoryset = (formData) => {
    return request.post<any,ApiResponse>('login/setFactoryset',{
        ...formData
    });
}