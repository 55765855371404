import React from "react";
import { Menu } from 'antd';
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { MENU, RawMiniProgramApply, SaleMiniProgramApply, UnattendedMenu } from "@/config/access";
import ToolAuth from "@/utils/toolauth";
import user from "@/utils/user";
import * as _ from "lodash";
import * as Icon from'@ant-design/icons';
import { inject } from "mobx-react";
import Config from "@/stores/config";

const SubMenu = Menu.SubMenu;


// const AuthMenuItem = WrapAuth(class extends React.Component{
//     // 构造
//     constructor(props) {
//         super(props);
//         // 初始状态
//     }
//     render(){
//         return (
//             <Menu.Item {...this.props} ></Menu.Item>
//         );
//     }
// });

const iconBC = (name:string) => {
    return React.createElement(Icon && (Icon as any)[name],{
        style:{fontSize:'16px'}
    })
}

class MyMenu extends React.Component<RouteComponentProps & {
    Config?:Config
}> {

    state: {
        openKeys: any[];
        currMenu: any;
    } = {
            openKeys: [],
            currMenu: ''
        };

    onOpenChange = (e) => {
        this.setState({
            openKeys: e,
        });
    };

    handleClick = (e) => {
        this.setState({
            currMenu: e.key,
        });
    };

    componentDidMount() {
        let path = this.props.location.pathname;
        let subItem: any = _.find(MENU, item => {
            if (item.children) {
                return _.some(item.children, { to: path });
            }
        });
        if (subItem) {
            this.state.openKeys.push(subItem.value);
        }
        console.log(this.state.openKeys);
        this.setState({
            openKeys: this.state.openKeys,
            currMenu: path
        });
    }

    renderMenuItem(value) {
        if(_.includes(SaleMiniProgramApply, value.value) && !this.props.Config?.commonConfig.isSaleMiniProgramApply){
            return null;
        }
        if(_.includes(RawMiniProgramApply, value.value) && !this.props.Config?.commonConfig.isRawMiniProgramApply){
            return null;
        }
        if(_.includes(UnattendedMenu, value.value) && !this.props.Config?.commonConfig.isUnattended){
            return null;
        }
        if (ToolAuth.checkAuth(value.value) || user.getUserRole() === 1) {
            if (_.get(value, 'children')) {
                return <SubMenu key={value.value} title={value.title} icon={value.icon ? iconBC(value.icon) : ''}>
                    {value.children.map(children => {
                        return this.renderMenuItem(children);
                    })}
                </SubMenu>
            } else {
                return <Menu.Item   key={value.to} icon={value.icon ? iconBC(value.icon) : ''}>
                    <NavLink to={value.to}>
                        {value.title}
                    </NavLink>
                </Menu.Item>;
            }
        }
    }

    renderMenu() {
        return _.map(MENU, value => this.renderMenuItem(value));
    }

    render() {
        return <Menu
        
            onClick={this.handleClick}
            selectedKeys={[this.state.currMenu]}
            openKeys={this.state.openKeys}
            onOpenChange={this.onOpenChange}
            theme="dark"
            mode="inline"
        >
            {this.renderMenu()}
            <Menu.Item>
                <a href="http://admin.yanshi.lxyly.cn:8089?token=1" target="_blank" rel="noopener noreferrer">岩石地磅管理</a>
            </Menu.Item>
            <Menu.Item >
                <a href="http://admin.kuangshi.lxyly.cn:8089?token=1" target="_blank" rel="noopener noreferrer">矿石地磅管理</a>
            </Menu.Item>
            <Menu.Item >
                <a href="http://admin.sjy297kuang.lxyly.cn:8089?token=1" target="_blank" rel="noopener noreferrer">-97地磅管理</a>
            </Menu.Item>
            <Menu.Item>
                <a href="http://admin.sjy302qi.lxyly.cn:8089?token=1" target="_blank" rel="noopener noreferrer">正30地磅管理</a>
            </Menu.Item>
        </Menu>
    }
}

export default inject((stores: any) => ({
    Config: stores.config
}))(withRouter(MyMenu));

// export default inject()();