import request, { ApiResponse } from "./request";

/**
 * 统计车辆api查询类
 */
export default class Home {
    /**
     * 厂内车辆
     * @returns {AxiosPromise<any>}
     */
    static inFactoryCar = () => {
        return request.get<any,ApiResponse>("index/inFactoryCar");
    };

    /**
     * 按照时间进行统计
     * @param startTime
     * @param endTime
     * @returns {AxiosPromise<any>}
     */
    static groupTimeData = (startTime, endTime) => {
        return request.get<any,ApiResponse>('index/groupTimeData', {
            params:{startTime, endTime}
        });
    };

    /**
     * 根据客户、品种、矿区 进行分组
     * @param startTime
     * @param endTime
     * @param group
     * @returns {AxiosPromise<any>}
     */
    static rankingGroup = (startTime, endTime, group) => {
        return request.get<any,ApiResponse>('index/rankingGroup', {
            params: {
                startTime,
                endTime,
                group
            }
        })
    };

    /**
     * 统计总数
     * @param startTime
     * @param endTime
     * @returns {AxiosPromise<any>}
     */
    static statistics = (startTime, endTime) => {
        return request.get<any,ApiResponse>('index/statistics', {
            params: {startTime, endTime}
        })
    }


    static initData = () => {
        return request.post<any,ApiResponse>('index/initData');
    }


}