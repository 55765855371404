import * as React from 'react';
import {Router} from "react-router-dom";
import Layout from "@/pages/layout";
import { Provider } from 'mobx-react';
import stores from '@/stores/index';
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { Route, Switch } from "react-router-dom";
import AsyncImport from './components/AsyncImport';
import AuthRouter from "./components/Router/AuthRouter";
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import './App.css';
import {createHashHistory} from "history";

// const createHashHistory = require("history").createHashHistory
const routingStore = new RouterStore();
const hashHistory = createHashHistory();
const history = syncHistoryWithStore(hashHistory, routingStore);
stores["routing"] = routingStore;

moment.locale('zh-cn');

const App: React.FC = () => {
  return (
    <Provider {...stores} >
        <Router history={history}>
          <ConfigProvider locale={zh_CN}>
            <div id="app">
                <Switch>
                    <Route path="/login" component={AsyncImport(() => import('@/pages/login/login'))} />
                    <AuthRouter  path="/" component={Layout} />
                </Switch>
            </div>
          </ConfigProvider>
        </Router>
      </Provider>
  );
}

export default App;
