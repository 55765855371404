import _ from "lodash";
import {MENU,ACTION} from "@/config/access";
import user from "@/utils/user";

// console.log(ACTION);

const getChildrenAction = (menu, key, parent) => {
    let actions:any = [];
    key = _.toUpper(_.snakeCase(key));
    if(_.get(ACTION, key)){
        let value = _.values(_.get(ACTION, key));
        _.each(value, action => {
            actions.push({
                title:action.title,
                key:action.value,
                parent:parent + "," + menu.value
            })
        });
        return actions;
    }
    return null;
};

export default class ToolAuth {

    static checkAuth(auth:any){
        let authValue = auth;
        if(_.isObject(auth)){
            authValue = (auth as any).value;
        }
        return _.includes(user.getUserAccess(), authValue);
    }

    static getAllAccessTreeData() {
        let treeData:any = [];
        _.forIn(MENU, (value, key) => {
            let obj = {
                title: value.title,
                key: value.value,
                parent:""
            };
            if (!_.isEmpty(value.children)) {
                let childrens:any = [];
                _.forIn(value.children, (submenu, subkey) => {
                    let childrenObj:any = {
                        title: submenu.title,
                        key: submenu.value,
                        parent:value.value
                    };
                    // console.log(submenu);
                    let actions = getChildrenAction(submenu, childrenObj.key, childrenObj.parent);
                    // console.log(actions);
                    if(actions){
                        childrenObj['children'] = actions;
                    }
                    childrens.push(childrenObj)
                });
                obj['children'] = childrens;
            }
            // 判断外部大菜单是否包含操作权限
            let actions = getChildrenAction(value, obj.key, obj.parent);
            if(actions){
                obj['children'] = actions;
            }
            treeData.push(obj)
        });
        return treeData;
    }
}