import * as _ from "lodash";

// 生成菜单value和子菜单value
const menuGenerateObject = (key, title, children:any = []) => {
    if (_.has(MENU_ROUTER, key) && _.get(MENU_ROUTER, key)) {
        let object = {
            value: _.kebabCase(key),
            title: title,
        };
        if (children.length > 0) {
            object['children'] = _.map(children, value => {
                let submenu = {value: _.kebabCase(value.key), title: value.title}
                return Object.assign({}, submenu, MENU_ROUTER[key]['submenu'][value.key]);
            });
        } else {
            object = Object.assign({}, object, MENU_ROUTER[key]);
        }
        object['icon'] = MENU_ROUTER[key].icon;
        return object;
    } else {
        console.error("请设置ROUTER");
    }
};

// 生成增删改查权限
const crudAuthorityItem = (key, chinese, filter:any = null) => {
    let kebabCase = _.kebabCase(key);
    let object = {
        ADD: {
            title: "新增" + chinese,
            value: kebabCase + '-add'
        },         /* 新增 */
        EDIT: {
            title: '修改' + chinese,
            value: kebabCase + '-edit'
        },
        DELETE: {
            title: '删除' + chinese,
            value: kebabCase + '-delete'
        }
    };
    if (filter) {
        return _.pick(object, filter);
    }
    return object;
};

// 将所有的权限值转换成一维数组
const deepFlatMap = function (value) {
    if (_.get(value, 'children')) {
        let values = _.map(value.children, (v) => {
            return deepFlatMap(v);
        });
        return _.concat(values, value.value);
    } else {
        let KeyMap = _.toUpper(_.snakeCase(value.value));
        let values:any = [];
        if (_.has(ACTION, KeyMap)) {
            values = _.flatMapDeep(_.get(ACTION, KeyMap), v => {
                return v.value;
            })
        }
        return _.concat(values, value.value);
    }
};

const MENU_ROUTER = {
    HOME: {to: '/home', icon: 'HomeOutlined'},
    // RAW_POUND: {to: '/raw/pound', icon: 'hdd'},
    // SALE_POUND: {to: '/raw/pound', icon: 'hdd'},
    RAW: {
        icon: 'ExceptionOutlined',
        submenu: {
            RAW_SUPPLIER: {to: '/raw/supplier'},
            RAW_CAR: {to: '/raw/car'},
            RAW_MATERIA: {to: '/raw/raw-materia'},
            RAW_MATERIA_PRICE: {to: '/raw/raw-materia-price'}
        }
    },
    SALE: {
        icon: 'BankOutlined',
        submenu: {
            SALE_CUSTOMER: {to: "/sale/customer"},
            SALE_CUSTOMER_CATE: {to:'/sale/customer-cate'},
            SALE_CUSTOMER_RECHARGE_RECORD: {to: "/sale/customer-recharge-record"},
            SALE_CUSTOMER_BALANCE_RECORD:{to: "/sale/customer-balance-record"},
            SALE_BALANCE_LOG:{to: "/sale/balance-log"},
            SALE_STONE: {to: "/sale/stone"},
            SALE_STONE_PRICE: {to: "/sale/stone-price"},
            SALE_CUSTOMER_TRUCK: {to: "/sale/customer-truck"},
            MINING_AREA:{to:'/sale/mining-area'},
            SALE_STONE_PACK:{to:'/sale/pack'}
        }
    },
    DATA_SOURCE: {
        icon: 'FileTextOutlined',
        submenu: {
            RAW_ORDER: {to: '/order/raw-order'},
            SALE_ORDER: {to: '/order/sale-order'},
            APPLY_ORDER_SALE: {to:'/apply-order/sale'},
            APPLY_ORDER_RAW: {to:'/apply-order/raw'},
            DISPATCH_ORDER_SALE: {to:'/dispatch-order/sale'},
            DISPATCH_ORDER_RAW: {to:'/dispatch-order/raw'},

            SALE_AREA_ORDER:{to:'/order/reconciliation/1'},
            SALE_CUSTOMER_ORDER:{to:'/order/reconciliation/2'},
            SALE_STATISTICAL_QRCODE:{to:'/report/statistical-qrcode/2'},
            SALE_AREA_QRCODE:{to:'/report/statistical-qrcode/1'}
        }
    },
    PHOTOS: {
        icon: 'PictureOutlined',
        submenu: {
            RAW_PHOTOS:{to: '/photos/raw-photo'},
            SALE_PHOTOS:{to: '/photos/sale-photo'}
        }
    },
    LOGS:{
        icon: 'TabletOutlined',
        submenu: {
            RAW_LOGS:{to:"/log/raw-log"},
            SALE_LOGS:{to:"/log/sale-log"}
        }
    },
    REPORT:{
        icon: 'PieChartOutlined',
        submenu:{
            SALE_STATISTICAL_REPORT:{to:'/report/sale-report/sale-statistics'},
            //{to:'/report/sale-report/'+encodeURIComponent(SALE_STATISTICAL_REPORT_URL)},
            SALE_DETAIL_REPORT:{to:'/report/sale-report/sale-detail'},
            // SALE_DETAIL_REPORT:{to:'/report/sale-report/'+encodeURIComponent(SALE_DETAIL_REPORT_URL)},
            SALE_GROUP_DETAIL_REPORT:{to:'/report/sale-report/sale-detail-group'},
            // SALE_GROUP_DETAIL_REPORT:{to:'/report/sale-report/'+encodeURIComponent(SALE_GROUP_DETAIL_REPORT_URL)},
            SALE_RECEIVABLE_REPORT:{to:'/report/sale-report/sale-receivable'},
            // SALE_RECEIVABLE_REPORT:{to: '/report/sale-report/'+encodeURIComponent(SALE_RECEIVABLE_REPORT_URL)},
            RAW_GROUP_DETAIL_REPORT:{to:'/report/sale-report/raw-detail-group'},
            // RAW_GROUP_DETAIL_REPORT:{to:'/report/sale-report/'+encodeURIComponent(RAW_GROUP_DETAIL_REPORT_URL)},
            RAW_GROUP_STATISTICAL_REPORT:{to:'/report/sale-report/raw-statistics'}
        }
    },
    FACTORY:{ 
        icon: 'GlobalOutlined',
        submenu:{
            ANNOUNCEMENTS:{to:'/announcement'},
            USER:{to:"/user/user"},
            PRINT_SET:{to:"/factory/print-set"},
            FACTORY_SET:{to:'/factory/factory-set/2'},
            RAW_FACTORY_SET:{to:"/factory/factory-set/1"},
            EDIT_PASS:{to:"/factory/editpass"},
            UNATTENDED_FACTORY_SET:{to:"/factory/factory-set/3"},
            FACTORY_INFO:{to:"/factory-info"}
        }
    }
    // USER: {to: "/user/user", icon: 'user'}
};

let MENUS = new Map();
MENUS.set('HOME', menuGenerateObject('HOME', '首页'));
//MENUS.set('RAW_POUND', menuGenerateObject('RAW_POUND', '原料过磅'));
//MENUS.set('SALE_POUND', menuGenerateObject('SALE_POUND', '销售过磅'));

// MENUS.set('SALE', menuGenerateObject('SALE', '销售管理', [
//     {key: 'SALE_CUSTOMER', title: '客户管理'},
//     {key: 'SALE_CUSTOMER_CATE', title: '客户类别'},
//     {key: 'SALE_CUSTOMER_RECHARGE_RECORD', title: '存款记录'},
//     {key: 'SALE_CUSTOMER_BALANCE_RECORD', title: '账目流水'},
//     {key: 'SALE_STONE', title: '品种管理'},
//     {key: 'SALE_STONE_PRICE', title: '品种价格管理'},
//     // {key: 'SALE_STONE_PACK', title: '品种打包管理'},
//     {key: 'SALE_CUSTOMER_TRUCK', title: '客户车辆管理'},
//     // {key: 'MINING_AREA', title: '矿区管理'}
// ]));
// MENUS.set('RAW', menuGenerateObject('RAW', '原料管理', [
//     {key: 'RAW_SUPPLIER', title: '供应商管理'},
//     {key: 'RAW_CAR', title: '供应商车辆管理'},
//     {key: 'RAW_MATERIA', title: '原材料管理'},
//     {key: 'RAW_MATERIA_PRICE', title: '原材料价格管理'}
// ]));
MENUS.set('DATA_SOURCE', menuGenerateObject('DATA_SOURCE', '数据管理', [
    {key: 'RAW_ORDER', title: '原料订单'},
   // {key: 'SALE_ORDER', title: '销售订单'},
    // {key: 'APPLY_ORDER_SALE', title: '销售申请单'},
    // {key: 'APPLY_ORDER_RAW', title: '原料申请单'},
    // {key: 'DISPATCH_ORDER_SALE', title: '销售派单'},
    // {key: 'DISPATCH_ORDER_RAW', title: '原料派单'}
    // {key: 'SALE_AREA_QRCODE', title: '扫码对账'},
    // {key: 'SALE_AREA_ORDER', title: '对账列表'}
  //  {key: 'SALE_CUSTOMER_ORDER', title: '客户对账列表'},
    
  //  {key: 'SALE_STATISTICAL_QRCODE', title: '客户扫码对账'}
]));
// MENUS.set('PHOTOS', menuGenerateObject('PHOTOS', '图片管理', [
//     {key: 'RAW_PHOTOS', title: '原料图片'},
//   //  {key: 'SALE_PHOTOS', title: '销售图片'}
// ]));
MENUS.set('LOGS', menuGenerateObject('LOGS', '日志管理', [
    {key: 'RAW_LOGS', title: '原料日志'},
 //   {key: 'SALE_LOGS', title: '销售日志'}
]));

MENUS.set('REPORT', menuGenerateObject('REPORT', '报表统计',[
    // {key: 'COMPREHENSIVE_REPORT', title: '综合图表'},
    // {key: 'RAW_STATISTICAL_REPORT', title: '原料统计'},
    // {key: 'RAW_DETAIL_REPORT', title: '原料明细'},
    
    // {key: 'SALE_STATISTICAL_REPORT', title: '销售统计报表'},
    // {key: 'SALE_DETAIL_REPORT', title: '销售明细报表'},
    // {key: 'SALE_GROUP_DETAIL_REPORT', title: '分组明细报表'},
    // {key: 'SALE_RECEIVABLE_REPORT', title: '应收账款'},
    {key: 'RAW_GROUP_DETAIL_REPORT', title: '进料报表'},
    {key: 'RAW_GROUP_STATISTICAL_REPORT', title: '进料统计报表'}
    // {key: 'SALE_RECONCILIATION_REPORT', title: '客户对账'}
]));
MENUS.set('FACTORY', menuGenerateObject('FACTORY', '厂区设置',[
    {key:"FACTORY_INFO", title:"厂区信息"},
 //   {key:'ANNOUNCEMENTS', title:'公告信息'},
    {key: 'USER', title: '账户管理'},
    {key: 'EDIT_PASS', title: '修改密码'}
 //   {key: 'PRINT_SET', title: '打印设置'},
  //  {key:'FACTORY_SET', title:'销售厂区设置'},
  //  {key:'RAW_FACTORY_SET', title:'原料厂区设置'},
 //   {key:'UNATTENDED_FACTORY_SET', title:'无人值守通用设置'}
   
    
]));
export const SaleMiniProgramApply = ['apply-order-sale','dispatch-order-sale','announcements'];
export const RawMiniProgramApply = ['apply-order-raw','dispatch-order-raw'];
export const UnattendedMenu = ['unattended-factory-set'];

let MENU:any = [];
MENUS.forEach((value, key) => {
    MENU.push(value);
});
// console.log(MENU);
// 销售过磅, 过磅照片，过磅视频， 财务报表，设置中心，日志查看

// 动作
export const ACTION = {
    // RAW_SUPPLIER: {...crudAuthorityItem('RAW_SUPPLIER', '供应商')}, /* 供应商权限管理 */
    // RAW_CAR: {...crudAuthorityItem('RAW_CAR', '供应商车辆')},          /* 供应商车辆权限管理 */
    // RAW_MATERIA: {...crudAuthorityItem('RAW_MATERIA', '原材料')},           /* 原材料权限管理 */
    // RAW_MATERIA_PRICE: {...crudAuthorityItem('RAW_MATERIA_PRICE', '原材料价格')},  /* 原材料价格权限管理 */
    // SALE_CUSTOMER: {...crudAuthorityItem('SALE_CUSTOMER', '客户'),...{
    //     RECHARGE:{title: "预存款充值", value: 'sale-customer-recharge'},
    //     FLOWING:{title: "查看流水", value: 'sale-customer-flowing'},
    //   //  USERAPP:{title: "分配小程序", value: 'sale-customer-appuser'},
    //     XIANGOU:{title: "品种限购", value: 'sale-customer-xiangou'},
    //     XINYONG:{title: "调整信用额度", value: 'sale-customer-xinyong'},
    // }},                              /* 客户权限管理 */
    // SALE_CUSTOMER_CATE: {...crudAuthorityItem('SALE_CUSTOMER_CATE', '客户类别'),...{
    //     DISTRIBUTE:{title: "分配客户", value: 'sale-customer-cate-distribute'}
    // }},                              /* 客户权限管理 */
    // SALE_CUSTOMER_RECHARGE_RECORD: {...crudAuthorityItem('SALE_CUSTOMER_RECHARGE_RECORD', '客户预存款', 'ADD'),...{
    //     PRINT:{title:'打印单据', value:'sale-customer-recharge-print'}
    // }}, /* 客户预存款权限管理 */
    // SALE_STONE: {...crudAuthorityItem('SALE_STONE', '品种')},                                            /* 品种权限管理 */
    // SALE_STONE_PRICE: {...crudAuthorityItem('SALE_STONE_PRICE', '品种价格'),...{
    //     BATCH:{title: "批量调价", value: 'sale-stone-price-batch'},
    // }},                         /* 品种价格权限管理 */
    // SALE_CUSTOMER_TRUCK: {...crudAuthorityItem('SALE_CUSTOMER_TRUCK', '客户车辆')},                       /* 客户车辆权限管理 */
    RAW_ORDER:{...crudAuthorityItem('RAW_ORDER', '原料订单'), ...{
        VIEW:{title: "查看", value: 'raw-order-view'},
        PRINT:{title:'打印单据', value:'raw-order-print'}
    }}, /** 原料订单 */
    // SALE_ORDER:{...crudAuthorityItem('SALE_ORDER', '销售订单'),...{
    //     VIEW:{title: "查看", value: 'sale-order-view'},
    //     SETTLE:{title: "人工结算", value: 'sale-order-settle'},
    //     HISTORY:{title:'查看过磅端修改记录', value: 'sale-order-history'},
    //     PRINT:{title:'打印单据', value:'sale-order-print'}
    // }}, /** 原料订单 */

    // APPLY_ORDER_SALE:{
    //     VIEW:{title:"查看", value:"apply-order-view-sale"},
    //     DELETE:{title:"删除", value:"apply-order-delete-sale"},
    //     EDIT:{title:"修改", value:"apply-order-edit-sale"},
    //     ADD:{title:"新增", value:"apply-order-add-sale"},
    //     sp:{title:"审批", value:"apply-order-sp-sale"},
    //     jujue:{title:"拒绝", value:"apply-order-jujue-sale"},
    //     without:{title:'免审核车辆查看', value:'without-approval-sale'},
    //     withoutAdd:{title:'新增免审核车辆', value:'without-approval-add-sale'},
    //     withoutEdit:{title:'修改免审核车辆', value:'without-approval-edit-sale'},
    //     withoutDelete:{title:'免审核删除', value:'without-approval-delete-sale'}
    // },
    // APPLY_ORDER_RAW:{
    //     VIEW:{title:"查看", value:"apply-order-view-raw"},
    //     DELETE:{title:"删除", value:"apply-order-delete-raw"},
    //     EDIT:{title:"修改", value:"apply-order-edit-raw"},
    //     ADD:{title:"新增", value:"apply-order-add-raw"},
    //     sp:{title:"审批", value:"apply-order-sp-raw"},
    //     jujue:{title:"拒绝", value:"apply-order-jujue-raw"},
    //     without:{title:'免审核车辆查看', value:'without-approval-raw'},
    //     withoutAdd:{title:'新增免审核车辆', value:'without-approval-add-raw'},
    //     withoutEdit:{title:'修改免审核车辆', value:'without-approval-edit-raw'},
    //     withoutDelete:{title:'免审核删除', value:'without-approval-delete-raw'}
    // },
    // DISPATCH_ORDER_RAW:{
    //     VIEW:{title:"查看", value:"dispatch-order-view-raw"},
    //     DELETE:{title:"删除", value:"dispatch-order-delete-raw"},
    //     sp:{title:"审批", value:"dispatch-order-sp-raw"},
    //     jujue:{title:"拒绝", value:"dispatch-order-jujue-raw"},
    //     trucks:{title:'车辆信息', value:'dispatch-order-trucks-raw'}

    // },
    // DISPATCH_ORDER_SALE:{
    //     VIEW:{title:"查看", value:"dispatch-order-view-sale"},
    //     DELETE:{title:"删除", value:"dispatch-order-delete-sale"},
    //     sp:{title:"审批", value:"dispatch-order-sp-sale"},
    //     jujue:{title:"拒绝", value:"dispatch-order-jujue-sale"},
    //     trucks:{title:'车辆信息', value:'dispatch-order-trucks-sale'}
    // },
    USER:{...crudAuthorityItem('USER', '用户')},
   // MINING_AREA:{...crudAuthorityItem('MINING_AREA','矿区')},
    HOME:{
        DAY:{title: "今日", value: 'home-day'},
        YESTERDAY:{title: "昨日", value: 'home-yesterday'},
        WEEK:{title: "本周", value: 'home-week'},
        MONTH:{title: "本月", value: 'home-month'},
        RANGDDATE:{title: "自定义时间", value: 'home-rangdate'},
        INITDATA:{title: "初始化数据", value:'init-data'}
    },
  //  ANNOUNCEMENTS:{...crudAuthorityItem('FACTORY_INFO', '公告信息')}
};

// 默认有权限的数据
export const DEFAULT_MENU = ['home','home-day'];

// 所有的一位数组权限
export const ALL_ACCESS = _.flatMapDeep(MENU, (value, index) => {
    return deepFlatMap(value);
});
export {
    MENUS,
    MENU
}



// 清晰明了的权限系统
// [
//     {
//         title:"首页",
//         name:"home",
//         icon:""
//     },
//     {
//         title:"采购管理",
//         name:"raw",
//         icon:"",
//         submenu:[{
//             title:"供应商管理",
//             icon:"",
//             name:"supplier",
//             $actions:[{
//                 name:"batch", title:"批量调价"
//             },{
//                 name:"add", title:"新增"
//             }]
//         }]
//     }
// ]

// auth = 'raw/supplier$batch'; // 路径方式


