import React, {Component} from 'react';
import ToolAuth from "@/utils/toolauth";
import user from "@/utils/user";
import {Button,Radio,DatePicker, Menu} from "antd";
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { ButtonProps } from 'antd/lib/button';
import { SubMenuProps } from 'antd/lib/menu/SubMenu';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { Moment } from 'moment';


const WrapAuth = <P extends {}>(ComposedComponent) => class WrapComponent extends Component<P & {auth:string}> {

    render() {
        
        if (ToolAuth.checkAuth(this.props.auth) || user.isSuper()) {
            return <ComposedComponent  { ...this.props} />;
        } else {
            return null; 
        }
    }
};

export const WrapAuthButton = WrapAuth<ButtonProps>(Button);
export const WrapAuthRadioButton = WrapAuth<RadioButtonProps>(Radio.Button);
export const WrapAuthRangePickerButton = WrapAuth<RangePickerProps<Moment>>(DatePicker.RangePicker);
export const WrapAuthMenuItem = WrapAuth<MenuItemProps>(Menu.Item);
export const WrapAuthMenuSubMenu = WrapAuth<SubMenuProps>(Menu.SubMenu);


export default WrapAuth;