import ConfigApi from "@/api/config";
import {action, observable, runInAction} from "mobx";
import {message} from "antd";
import * as _ from "lodash";
import {getFactoryset} from "@/api/login"

/**
 * 客户管理
 */
class Config{

    @observable business = {}

    @observable printConfig:any = {};

    @observable commonConfig:any = {};

    @observable factorySet = {};

    @action.bound async init(){
        let result = await ConfigApi.list();
        if(result.status){
            runInAction(() => {
                let configData = result.data;
                this.business = _.zipObject(_.map(configData,'key'),_.map(configData,'value')); 
            })
        }else{
            message.error('获取配置失败');
        }

        let printConfig = await ConfigApi.list(10);
        if(printConfig.status){
            runInAction(() => {
                let configData = printConfig.data;
                this.printConfig = _.zipObject(_.map(configData,'key'),_.map(configData,'value')); 
            })
        }


        let commonConfig = await ConfigApi.list(0);
        if(commonConfig.status){
            runInAction(() => {
                let configData = commonConfig.data;
                this.commonConfig = _.zipObject(_.map(configData,'key'),_.map(configData,'value')); 
            })
        }
    }

    @action.bound async initFactorySet(){
        let result = await getFactoryset();
        if(result.status){
            runInAction(() => {
                let factorySetData = result.data;
                this.factorySet = factorySetData;
               // window.document.querySelector('title').
                window.document.querySelector('title')!!.innerText = result.data.factory_name
            })
        }else{
            message.error('获取factoryset失败');
        }
    }

}

export default Config;